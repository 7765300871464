
    <div *ngIf="elementsOptions && stripe" class="flex flex-column min-h-full justify-content-between">

      <ng-container *ngIf="isMobile">
        <ng-container *ngTemplateOutlet="overviewTemplate; context: { paymentInProgress: paying }"></ng-container>

        <p-message *ngIf="error" severity="error" [text]="error" styleClass="mb-4 w-full text-left">
        </p-message>

        <ngx-stripe-elements
          [stripe]="stripe!"
          [elementsOptions]="elementsOptions"
        >
          <ngx-stripe-payment [options]="paymentElementOptions" />
        </ngx-stripe-elements>
        <ng-container *ngTemplateOutlet="actionButtons"></ng-container>

        <ng-container *ngTemplateOutlet="disclaimerTemplate"></ng-container>
      </ng-container>

      <ng-container class="mb-5" *ngIf="!isMobile">
        <div>
          <p-message *ngIf="error" severity="error" [text]="error" styleClass="mb-4 w-full text-left">
          </p-message>

          <ngx-stripe-elements
            [stripe]="stripe!"
            [elementsOptions]="elementsOptions"
          >
            <ngx-stripe-payment [options]="paymentElementOptions" />
          </ngx-stripe-elements>
        </div>

        <div>
          <ng-container *ngTemplateOutlet="actionButtons"></ng-container>
        </div>
      </ng-container>
    </div>

    <ng-template #actionButtons>
      <p-button i18n-label label="Confirm" styleClass="w-full mt-3" (click)="pay()" [loading]="paying()" [disabled]="checkoutButtonDisabled() || !paymentElement"></p-button>

      <ng-container *ngIf="alternativePaymentOptionTemplate">
        <p-divider align="center" i18n>
          OR
        </p-divider>

        <ng-container *ngTemplateOutlet="alternativePaymentOptionTemplate; context: alternativePaymentOptionTemplateContext"></ng-container>
      </ng-container>
    </ng-template>
