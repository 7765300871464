@if(isBrowser) {
  <p-dialog [(visible)]="loadingDialogVisible" [modal]="true">
    <ng-template pTemplate="headless">
      <div class="surface-card border-round p-5">
        <div class="w-20rem flex align-items-center justify-content-center flex-column">
          <i class="pi pi-spinner p-icon-spin text-primary text-7xl"></i>
          <div class="text-lg mt-3" i18n>Waiting for payment confirmation</div>
        </div>
      </div>
    </ng-template>
  </p-dialog>

  <p-dialog [(visible)]="errorDialogVisible" [modal]="true">
    <ng-template pTemplate="headless">
      <div class="surface-card border-round p-5">
        <div class="w-20rem flex align-items-center justify-content-center flex-column">
          <div class="text-lg mt-3" i18n>Unable to confirm payment</div>
          <div class="mt-2" i18n>Please try to reload the page</div>

          <p-button i18n-label icon="pi pi-times" label="Close" class="mt-4" (click)="errorDialogVisible = false"></p-button>
        </div>
      </div>
    </ng-template>
  </p-dialog>
}
