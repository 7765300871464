import { Component, DestroyRef, EventEmitter, Inject, inject, Output, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { debounceTime, firstValueFrom } from "rxjs";
import { EventsService } from "../../../features/events/services/events.service";
import { DialogService } from "primeng/dynamicdialog";
import { DialogModule } from "primeng/dialog";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { Button } from "primeng/button";
import { isPlatformBrowser } from "@angular/common";

@Component({
  selector: 'cs-payment-processing-status-dialog',
  standalone: true,
  imports: [
    DialogModule,
    ProgressSpinnerModule,
    Button
  ],
  templateUrl: './payment-processing-status-dialog.component.html',
  styleUrl: './payment-processing-status-dialog.component.scss'
})
export class PaymentProcessingStatusDialogComponent {

  @Output() onPaymentSuccess: EventEmitter<string> = new EventEmitter<string>();

  loadingDialogVisible = false;
  errorDialogVisible = false;

  destroyRef = inject(DestroyRef);

  isBrowser = false;

  constructor(private route: ActivatedRoute, private router: Router, private eventsService: EventsService, private dialogService: DialogService, @Inject(PLATFORM_ID) private platformId: string) {}

  ngOnInit(): void {
    this.isBrowser = isPlatformBrowser(this.platformId);
    // check query string parameters for checkout success
    this.route.queryParams.pipe(
      takeUntilDestroyed(this.destroyRef),
      debounceTime(100),
    ).subscribe(params => {
      if (params['payment_intent'] && this.isBrowser) {
        if (params['payment_intent'] == 'CASH') {
          console.log('not showing join success dialog after cash payment');
          this.onPaymentSuccess.emit('CASH');

          this.router.navigate([], {
            queryParams: {
              payment_intent: null, payment_intent_client_secret: null, redirect_status: null
            }, queryParamsHandling: 'merge', replaceUrl: true
          });
          return;
        }

        this._checkCheckoutSucceeded(params['payment_intent']);
      }
    })
  }

  async _checkCheckoutSucceeded(paymentIntendId: string, {retryCount = 0} = {}) {
    this.loadingDialogVisible = true;
    try {
      const checkoutSessionStatus = await firstValueFrom(this.eventsService.getPaymentIntentStatus(paymentIntendId));

      if (checkoutSessionStatus === 'succeeded') {
        console.log('showing join success dialog after checkout succeeded for payment id', paymentIntendId);
        this.loadingDialogVisible = false;

        this.onPaymentSuccess.emit(paymentIntendId);

        await this.router.navigate([], {
          queryParams: {
            payment_intent: null, payment_intent_client_secret: null, redirect_status: null
          }, queryParamsHandling: 'merge', replaceUrl: true
        });
      } else if (checkoutSessionStatus !== 'canceled' && retryCount < 3) {
        // wait 1 second and check again
        setTimeout(() => {
          this._checkCheckoutSucceeded(paymentIntendId, {retryCount: retryCount + 1});
        });
      } else if (checkoutSessionStatus == 'canceled') {
        console.log('checkout canceled');
        this.loadingDialogVisible = false;
      }else {
        console.log('checkout failed or canceled');
        this.loadingDialogVisible = false;
        this.errorDialogVisible = true;
      }
    } catch (e) {
      console.error('error checking checkout status', e);
      this.loadingDialogVisible = false;
      this.errorDialogVisible = true;
    }
  }
}
