import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StripeCheckoutComponent } from "./components/stripe-checkout/stripe-checkout.component";
import { MessageModule } from "primeng/message";
import { StripeElementsDirective, StripePaymentElementComponent } from "ngx-stripe";
import { Button, ButtonDirective } from "primeng/button";
import { DividerModule } from "primeng/divider";



@NgModule({
  declarations: [
    StripeCheckoutComponent
  ],
  exports: [
    StripeCheckoutComponent
  ],
  imports: [
    CommonModule,
    MessageModule,
    StripeElementsDirective,
    StripePaymentElementComponent,
    Button,
    DividerModule,
    ButtonDirective
  ]
})
export class CheckoutModule { }
