import { Component, EventEmitter, Inject, Input, Output, PLATFORM_ID, signal, TemplateRef } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { Store } from "@ngrx/store";
import { selectAuthIsLoggedIn } from "../../../auth/store/auth.selectors";
import { combineLatestWith, map, Observable } from "rxjs";
import { selectPlayerSelfIsOnboarded } from "../../../player-self/store/player-self.selectors";
import { AsyncPipe, isPlatformBrowser, NgIf, NgTemplateOutlet } from "@angular/common";
import { AuthModule } from "../../../auth/auth.module";
import { InitializedPayment } from "../../../../shared/graphql/generated/graphql";
import { CheckoutModule } from "../../checkout.module";
import { CheckoutParams, PaymentStatus } from "../stripe-checkout/stripe-checkout.component";

@Component({
  selector: 'cs-checkout-dialog',
  standalone: true,
  imports: [
    AsyncPipe,
    AuthModule,
    NgIf,
    NgTemplateOutlet,
    CheckoutModule,
  ],
  templateUrl: './checkout-dialog.component.html',
  styleUrl: './checkout-dialog.component.scss'
})
export class CheckoutDialogComponent {

  @Input({ required: true }) overviewTemplate!: TemplateRef<any>;
  @Input({ required: true }) disclaimerTemplate!: TemplateRef<any>;
  @Input() alternativePaymentOptionTemplate?: TemplateRef<any>;

  @Input({ required: true }) checkoutParams$!: Observable<CheckoutParams>;
  @Input({ required: true }) initializePaymentFn!: (params: CheckoutParams) => Observable<InitializedPayment>;
  @Input() alternativePaymentMethodFn?: (params: CheckoutParams) => Observable<any>;

  @Output() paymentSucceeded = new EventEmitter<string>();

  constructor(public dialogRef: DynamicDialogRef,
              private dialogConf: DynamicDialogConfig,
              private store: Store, @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  showLoginAndOnboarding$ = this.store.select(selectAuthIsLoggedIn).pipe(
    combineLatestWith(this.store.select(selectPlayerSelfIsOnboarded)),
    map(([isLoggedIn, isOnboarded]) => !isLoggedIn || !isOnboarded)
  )

  onPaymentSucceeded(paymentIntendId: string) {
    this.dialogRef.close(paymentIntendId);
    this.paymentSucceeded.emit(paymentIntendId);
  }

  paymentInProgress = signal(false);

  onPaymentStatusChanged(paymentStatus: PaymentStatus) {
    if (paymentStatus == PaymentStatus.inProgress) {
      this.paymentInProgress.set(true);
    } else {
      this.paymentInProgress.set(false);
    }
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {

      const innerWidth = window.screen.width;
      const innerHeight = window.screen.height;

      if (innerWidth < 768) {
        this.dialogConf.width = '100vw';
      }
    }
  }

}
